<template>
  <div class="row">
    <div class="col-md-12">
      <dashboard-box :back="true" back-component-name="management.fabric.index">
        <template v-slot:title>
          <span>{{ $t(id != null ? 'fabric.edit_fabric' : 'fabric.create_fabric') }}</span>
        </template>
        <template v-slot:preview>
          <div class="row d-flex" v-if="fabricProxy">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12" v-for="(value, index) in fabricProxy.translations"
              :key="index">
              <text-input :key="index"
                :title="`${$t('fabric.fabric_name')}(${getLanguageById(value.language_id).name})`"
                :model.sync="value.name"
                :placeholder="`${$t('fabric.please_enter_fabric_name')}(${getLanguageById(value.language_id).name})`"
                :is-valid="value != null && value.name.length !== 0 ? true : null"></text-input>
            </div>

            <div class="col-xl-3">
              <div class="d-flex items-end justify-content-start">
                <custom-multi-select :title="$t('expense.expense_logo_name')" :model.sync="fabricProxy.logo_id"
                  :options="convertArrayToObjectByKey(logoItems, 'logicalref', 'name')" name="fabric_logo_name"
                  :not-list="true" :is-inline="false" :max="1" :required="true" :item-per-row="1" input-width="auto"
                  input-max-width="200px"></custom-multi-select>
              </div>
            </div>

            <div class="col-xl-3">
              <div class="d-flex items-end justify-content-start">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <custom-multi-select :show-invalid="false" :options="units" :model.sync="fabricProxy.unit_id"
                    :title="$t('general.unit')" name="unit_id" :max="1"
                    :placeholder="$t('general.please_select_option')" :not-list="true"
                    :is-inline="false"></custom-multi-select>
                </div>
              </div>
            </div>

            <div class="col-6 row">
              <div class="col-12">
                <span class="cursor-pointer" @click="addOptionalOptions()" v-html="getIconByKey('icons.model_house.add', {
                  class: 'w-20px h-20px d-inline-block object-cover'
                })"></span>
              </div>

              <div class="row d-flex w-100" v-for="(options, index) in fabricProxy.optional_options" :key="index">
                <div class="col-5">
                  <div class="d-flex items-end justify-content-start">
                    <custom-multi-select 
                        :show-invalid="false" 
                        :title="$t('expense.expense_optional_logo_name')"
                        :model.sync="options.optional_logo_id"
                        :options="convertArrayToObjectByKey(logoItems, 'logicalref', 'name')" 
                        name="fabric_optional_logo_name" 
                        :not-list="true" :is-inline="false" 
                        :max="1" 
                        :required="true"
                        :item-per-row="1" 
                        input-width="auto" 
                        input-max-width="200px">
                      </custom-multi-select>
                  </div>
                </div>

                <div class="col-5">
                  <div class="d-flex items-end justify-content-start">
                    
                      <custom-multi-select :show-invalid="false" :options="units" :model.sync="options.optional_unit_id"
                        :title="$t('general.optional_unit')" name="optional_unit_id" :max="1" :required="true"
                        :placeholder="$t('general.please_select_option')" :not-list="true" :is-inline="false"
                        :item-per-row="1"></custom-multi-select>
                  </div>
                </div>
                <div class="col-1">
                  <span class="cursor-pointer" @click="deleteOptionalOptions(index)" v-html="getIconByKey('icons.sample.delete_red', {
                        class: 'w-20px h-20px d-inline-block object-cover'
                      })"></span>
              </div>
              </div>

              
            </div>


          </div>


          <div class="d-flex justify-content-center mt-5">
            <button-with-icon class="mr-3 danger-border-1px" @onClick="$router.go(-1)" :text="$t('general.cancel')"
              size="lg" :icon-name="null"></button-with-icon>
            <button-with-icon @onClick="createOrUpdateFabric" :text="$t(id != null ? 'general.save' : 'general.create')"
              size="lg" :icon-name="null" :disabled="isValidToCreate"></button-with-icon>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapMutations, mapGetters } from 'vuex'
import {
  CREATE_ITEM,
  CURRENT_ITEM,
  ERROR, GET_ITEMS,
  GET_ITEM_DETAIL_BY_ID,
  LOADING, SET_CURRENT_ITEM, SUCCESS, UPDATE_ITEM_BY_ID
} from "@/core/services/store/panel-managment/crud/panel.management.module";
import TextInput from "@/assets/components/inputs/TextInput";
import SelectInput from "@/assets/components/inputs/SelectInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import { LANGUAGE } from "@/core/services/store/system-options.module";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";

import unitModule, {
  BASE_URL as UNIT_BASE_URL,
  ITEMS as UNIT_ITEMS,
  GET_ITEMS as UNIT_GET_ITEMS, MODULE_NAME as MODULE_NAME_UNIT,
} from "@/core/services/store/unit/unit.module";
import store from "@/core/services/store";
import { GET_ITEMS as GET_ITEMS_COMPANY, ITEMS as ITEMS_COMPANY } from "@/core/services/store/company/company.module";

const _MODULE_NAME_UNIT = MODULE_NAME_UNIT;

export default {
  name: "FabricCreateEdit",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }

    registerStoreModule(_MODULE_NAME_UNIT, unitModule)
  },
  components: {
    ButtonWithIcon,
    TextInput,
    SelectInput,
    CustomMultiSelect,
    DashboardBox
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      error: ERROR,
      success: SUCCESS,
      fabric: CURRENT_ITEM,
      language: LANGUAGE,
    }),
    units() {
      let items = store.getters[_MODULE_NAME_UNIT + '/' + UNIT_ITEMS];
      if (items) {
        return this.convertArrayToObjectByKey(items, 'id', 'logo_name');
      }
      return {};
    },
    isValidToCreate() {
      if (this.fabric == null || this.fabric.translations == null || this.fabric.translations.length === 0) return true;

      for (let i = 0; i < this.fabric.translations.length; i++) {
        if (this.fabric.translations[i].name == null || this.fabric.translations[i].name.length === 0) {
          return true;
        }
      }

      return false;
    },
    fabricProxy: {
      get() {
        if (this.id != null && this.fabric) {
          this.logo_id = this.fabric.logo_id;
          this.logoItems = this.fabric.cache_items;
        }
        return this.fabric;
      },
      set(value) {
        this.setFabric(value);
      }
    }
  },
  data() {
    return {
      id: null,
      languages: [],
      logoItems: [],
      logoOptions: [],
      logo_id: this.id ? this.fabricProxy.logo_id : null,
      tempItem: {
        translations: []
      }
    };
  },
  methods: {
    ...mapMutations({
      setFabric: SET_CURRENT_ITEM,
    }),
    getUnits() {
      this.$store.dispatch(_MODULE_NAME_UNIT + '/' + UNIT_GET_ITEMS, {
        filters: {}
      });
    },
    getCacheData() {
      let payload = {
        url: 'api/fabrics/cache-data',
        returnType: "stateless"
      }

      this.$store.dispatch(GET_ITEMS, payload).then(response => {

        if (response.status) {
          this.logoItems = response.data;
        }
      })
    },
    deleteOptionalOptions(index){
      this.fabricProxy.optional_options.splice(index, 1);
    },
    addOptionalOptions() {
      if (this.fabricProxy.optional_options === null) {
        this.fabricProxy.optional_options = [];
      }
      this.fabricProxy.optional_options.push({
        optional_logo_id: [],
        optional_unit_id: []
      })
    },
    createOrUpdateFabric() {
      let contents = [];

      this.fabricProxy.translations.forEach(item => {
        contents.push(item)
      })

      let payload = {
        url: this.id == null ? 'api/fabrics' : 'api/fabrics/' + this.id,
        id: this.id,
        contents: {
          translations: contents,
          logo_id: this.logo_id,
          unit_id: this.fabricProxy.unit_id,
          optional_options: this.fabricProxy.optional_options,
        },
        redirectRouteName: 'management.fabric.index',
        successMessage: this.$t(this.id == null ? 'general.successfully_created' : 'general.successfully_updated'),
      }
      this.$store.dispatch(this.id == null ? CREATE_ITEM : UPDATE_ITEM_BY_ID, payload);

    },
  },
  mounted() {
    this.id = this.$route.params.id;

    this.getCacheData();

    if (this.id != null) {
      let payload = {
        url: 'api/fabrics/' + this.id,
        id: this.id,
        redirectRouteOnNotFound: 'management.fabric.index'
      }

      this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, payload)
    }

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("fabric.self"), route: "management.fabric.index" },
      { title: this.$t(this.id != null ? "fabric.edit_fabric" : "fabric.create_fabric") },
    ]);

    this.languages = this.convertArrayToObjectByKey((this.systemLanguages), 'id', 'name');

    if (_.isEmpty(this.units)) {
      this.getUnits();
    }

    if (!this.id) {
      let languages = this.systemLanguages;
      let self = this;

      languages.forEach(item => {
        self.tempItem.translations.push({
          language_id: item.id,
          name: "",
        })
      })
      this.setFabric(self.tempItem)
    }
  }
}
</script>

<style scoped>
#dashboard-box {
  padding: 10px;
}
</style>
